<template>
  <TsSection append-class="relative" :id="props.id || 'section-product-card-swiper' + randomId">
  <TsIcon name="bitcoin-icons:cross-filled" @click="handleDrawer" 
      class="absolute top-3 md:top-7 right-2 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer" />
    <TsColumn append-class="p-0 pt-4 lg:pt-8">
      <TsTypography append-class="text-idle-black text-2xl leading-5 mb-3" weight="bold">{{ props.sectionTitle }}</TsTypography>
      <TsTypography as="md" append-class="leading-5 mb-0">Comparing {{ compareStore.checkedProduts.length }} products
      </TsTypography>
      <TsDivider />
      <TsRow append-class="m-0 my-2 gap-2 items-center justify-between md:justify-start">
        <TsTypography append-class="text-idle-black leading-5" weight="semibold">Show me the differences</TsTypography>
          <TsToggle data-testid="products-compare-all-difference-only-toggle" appear-class="text-primary bg-primary" :bgPrimary="true"
            v-model="compareStore.isDifferenceOnlyEnabled" @click="handleCompare" />
      </TsRow>
    </TsColumn>

    <TsRow append-class="m-0 py-1 gap-2 overflow-auto no-scrollbar">
      <div v-for="product in filteredProducts" :key="product.code"
        :class="twJoin('w-full min-w-[165px] md:max-w-[300px] md:min-w-[280px]', product.code === compareStore.freezedProductCode && 'sticky left-0 z-30 order-first transition-all')">
        <TsCardCompare data-testid="product-comparison-all-card" :product="product"
          :is-freezed="product.code === compareStore.freezedProductCode"
          @freeze="compareStore.toggleFreeze(product.code)" @removeItem="handleRemoveItem(product)" />
      </div>
      <TsCard append-class="no-border drop-shadow-[1px_5px_12px_0px_#00152A0F] w-full min-w-[165px] md:max-w-[300px] md:min-w-[300px] bg-white flex items-center justify-center">
          <TsButton size="xs" :disabled="compareStore.checkedProduts.length === (device.isMobile ? 4 : 8)" block @click="emit('close')">
            <TsIcon name="ph:plus-bold" size="16" />
            <span>Add a product</span>
          </TsButton>
      </TsCard>
    </TsRow>
  </TsSection>
</template>

<script setup lang="ts">
import { twJoin } from "tailwind-merge";
import { onMounted } from "vue";
import type { Product } from "~/types/ecom/product/product.type";

type Props = {
  id?: string;
  sectionTitle?: string;
};

const props = withDefaults(defineProps<Props>(), {
  sectionTitle: "Product Comparison",
});

const emit = defineEmits<{
  differenceOnly: any;
  close: any;
}>();

const randomId = ref("");
const compareStore = useCompareStore();
const allProducts = ref<Product[]>();
const commonKeys = ref<string[]>([]);
const excludeKeys = new Set(['EAN', 'Brand', 'Manufacturer ID']);
const device = useDevice();

const findCommonAttributes = (products: Product[]) => {
  const attributeMap = new Map<string, Set<string>>();
  products.forEach(product => {
    product?.technical_spec_attributes?.forEach(attr => {
      if (!excludeKeys.has(attr.key)) {
        if (!attributeMap.has(attr.key)) {
          attributeMap.set(attr.key, new Set());
        }
        attributeMap.get(attr.key)?.add(attr.value);
      }
    });
  });
  const commonKeys = [...attributeMap.entries()].filter(([_, values]) => values.size === 1).map(([key]) => key);
  return commonKeys;
};

const filteredProducts = computed(() => {
  const products = compareStore.checkedProduts.map(product => ({
    ...product,
    technical_spec_attributes: product?.technical_spec_attributes?.filter(attr => !excludeKeys.has(attr.key))
  }));

  if (compareStore.isDifferenceOnlyEnabled) {
    commonKeys.value = findCommonAttributes(products as any);
    return products.map(product => ({
      ...product,
      technical_spec_attributes: product?.technical_spec_attributes?.filter(attr => !commonKeys.value.includes(attr.key)),
    }));
  }
  
  return products;
});

const handleRemoveItem = (item: Product) => {
  compareStore.checkedProduts = compareStore.checkedProduts?.filter(p => p.code !== item.code);
  if(compareStore.checkedProduts.length === 0) compareStore.enableExpand = false;
};

onMounted(() => {
  randomId.value = useRandomUUID();
  allProducts.value = compareStore.getProductsForComparison();
});

function handleCompare() {
  emit('differenceOnly');
}

function handleDrawer() {
  compareStore.isSpecificationEnable = false;
  compareStore.isDifferenceOnlyEnabled = false;
  emit('close');
}
</script>
